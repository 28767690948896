import { gql } from "graphql-tag";

export default function PopupAlertQuery(date: String, langcode: String) {
  const gqlConcat = gql`
 query PopupQuery {
   entityQuery(
     entityType: BLOCK_CONTENT
     filter: {
       groups: {
        conditions: {
          field: "langcode", 
          value: "${langcode}"
        }, 
        groups: {
          conditions: {
            field: "field_date_begin"
            operator: SMALLER_THAN_OR_EQUAL
            value: "${date}"
          }
          groups: {
            conditions: {
              field: "field_date_end"
              operator: GREATER_THAN_OR_EQUAL
              value: "${date}"
            }
            groups: {
              conditions: { field: "type", value: "block_popup_alert" }
            }
          }
        }
      }
     }
   ) {
     items {
       ... on BlockContentBlockPopupAlert {
         id
         type: entityBundle
         fieldTitle
         fieldTextBanner
         fieldTextPopup
         fieldImage {
           alt
           entity {
             uriRawField {
               list {
                 url
               }
             }
           }
         }
         fieldLink {
           title
           uri {
             path
           }
         }
         fieldDateBegin {
           value
         }
         fieldDateEnd {
           value
         }
       }
     }
   }
 }
`;
  return gqlConcat;
}

<template>
  <ul
    class="hidden absolute top-0 left-1/2 z-10 p-8 w-1/2 h-full font-normal bg-grisBackground rounded-b-md text-md group-hover:block border-l border-grisClair"
  >
    <li
      v-for="(subSubItem, subSubIndex) in subItem.links"
      :key="subSubIndex"
      class="mb-2 text-grisFont hover:text-orangeCurie"
      :class="
        subSubItem.link.class === 'link_promote'
          ? 'flex items-center mt-6 text-lg font-bold underline font-raleway'
          : ''
      "
    >
      <UiCustomLink :path="subSubItem.link.url.path">
        {{ subSubItem.link.label }}
      </UiCustomLink>
      <nuxt-icon
        v-if="subSubItem.link.class === 'link_promote'"
        name="arrowRight"
        filled
        class="mt-1 ml-2"
      />
    </li>
  </ul>
</template>
<script lang="ts">
export default {
  name: "SubSubMenu",
  props: ["subItem"],
  methods: {
    getRelativePath(fullUrl: string): string {
      const url = new URL(fullUrl);
      return url.pathname;
    },
  },
};
</script>

import { defineStore } from "pinia";
import type { BreadcrumbItems } from "~/interfaces/breadcrumb";

export const STORE_NAME = "breadcrumb";

type BreadcrumbState = {
    _breadcrumb?: BreadcrumbItems;
}

export const useBreadcrumbStore = defineStore(STORE_NAME, {
    state: () => ({
        _breadcrumb: undefined,
    } as BreadcrumbState),
    actions: {
        setBreadcrumb(value:BreadcrumbItems) {
            this._breadcrumb = value;
        }
    },
    getters: {
        breadcrumb: (state) =>  state._breadcrumb,
    }
});

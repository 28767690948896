<template>
  <li class="ml-0">
    <NuxtLink
      class="relative cursor-pointer underline-animation"
      :class="{ 'underline-fixed': activeItemIndex === index }"
      @click="toggleSubmenu"
    >
      {{ item.link.label }}
    </NuxtLink>
    <SubMenu
      v-if="item.links.length > 0"
      :blockMenu="findMenuItemByClass(item.links, 'block_menu')?.links || []"
      :blockLinks="findMenuItemByClass(item.links, 'block_links')?.links || []"
      :blockImage="findMenuItemByClass(item.links, 'block_image')?.links || []"
      :itemTitle="item.link"
      :active="activeItemIndex === index"
    />
  </li>
</template>

<script>
import SubMenu from "./SubMenu.vue";

export default {
  name: "MenuElement",
  components: {
    SubMenu,
  },
  props: ["item", "index", "activeItemIndex"],
  methods: {
    toggleSubmenu() {
      this.$emit("toggleSubmenu", this.index);
    },
    findMenuItemByClass(links, className) {
      if (!links) return null;
      return links.find((link) => link.link.class === className);
    },
  },
};
</script>

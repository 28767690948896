import PopupAlertQuery from "../../query/content/PopupAlert";
const TAGS = ["BLOCK_POPUP_ALERT"];

export default async function getAlertPopup(today: string) {
  const cacheKey = `BLOCK_POPUP_ALERT_${today}`;
  const { data: alertPopup } = await useAsyncData(cacheKey, async () => {
    const { value, addToCache } = await useDataCache(cacheKey)
   
    if (value) {
      return value
    }
    const runtimeConfig = useRuntimeConfig();
    const { data: response } = await useAsyncQuery(PopupAlertQuery(today, runtimeConfig.public.langcode));
    TAGS.push(cacheKey);
    await addToCache(response._value, TAGS);
    return response._value;
  })
  return {data: alertPopup._value };
}

<template>
  <div class="border-t border-b border-grisClair">
    <ul class="flex flex-col p-4">
      <li v-for="(item, index) in linksItems" :key="index">
        <UiCustomLink
          :path="item.link?.url?.path"
          class="flex items-center my-2 text-lg font-bold underline font-raleway"
        >
          <nuxt-icon :name="getIconName(item.link?.class)" filled class="mr-2" />
          {{ item.link?.label }}
          <nuxt-icon name="arrowRight" filled class="mt-1 ml-2" />
        </UiCustomLink>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { type MenuLinkTreeElement } from "~/interfaces/menu";

export default {
  name: "MobileBlocLinks",
  props: {
    linksItems: {
      type: Array as () => MenuLinkTreeElement[],
      required: true,
    },
  },
  methods: {
    getIconName(itemClass: string | null): string {
      const classToIconMapping: { [key: string]: string } = {
        link_calendar: "calendar",
        link_account: "user",
        link_world: "world",
        link_donor: "donor"
      };
      //TODO: Changer l'icone par defaut
      return classToIconMapping[itemClass || ""] || "arrowRight";
    },
  },
};
</script>

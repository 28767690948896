<template>
  <ul
    class="absolute transition duration-700 ease-in-out top-[66px] px-8 left-[-32px] min-h-[200px] bg-white border border-grisClair border-t-0 rounded-b-md text-lg z-10 w-[800px]"
    :class="{
      'opacity-100 visible': active,
      'opacity-0 invisible': !active,
    }"
  >
    <li class="pt-8 pb-2 w-1/2 font-bold hover:text-orangeCurie">
      <UiCustomLink :path="itemTitle.url.path">
        {{ itemTitle.label }}
      </UiCustomLink>
    </li>
    <li
      v-for="(subItem, subIndex) in blockMenu"
      :key="subIndex"
      class="w-1/2 group hover:text-orangeCurie last-of-type:pb-8 border-t border-grisClair"
    >
      <UiCustomLink
        :path="subItem.link.url.path"
        class="items-center border-grisClair justify-between flex w-[95%] pt-2 mb-2 font-normal"
      >
        {{ subItem.link.label }}
        <nuxt-icon v-if="subItem.links.length > 0" name="chevronRight" filled />
      </UiCustomLink>
      <SubSubMenu v-if="subItem.links.length > 0" :subItem="subItem" />
    </li>
    <ul v-if="blockLinks.length > 0" class="py-6 border-t -mx-8 px-8 w-[calc(50%+2rem)]">
      <li v-for="(link, index) in blockLinks" :key="index">
        <UiCustomLink :path="link.link?.url?.path" class="flex items-center underline">
          <nuxt-icon :name="getIconName(link.link?.class)" filled class="mr-2" />
          {{ link.link?.label }}
        </UiCustomLink>
      </li>
    </ul>
    <!-- Section avec image et lien -->
    <div class="absolute top-0 left-1/2 z-0 p-8 w-1/2 h-full border-l border-grisClair">
      <li v-for="(item, index) in blockImage" :key="index">
        <NuxtImg
          v-if="item.link.class === 'link_image'"
          :src="`/image/${item.link.image}`"
          :alt="item.link.label"
          class="w-full rounded"
        />
        <UiCustomLink
          :path="item.link.url.path"
          v-if="item.link.class !== 'link_image'"
          class="flex items-center my-6 text-lg font-bold underline font-raleway"
        >
          {{ item.link.label }}
          <nuxt-icon name="arrowRight" filled class="mt-1 ml-2" />
        </UiCustomLink>
      </li>
    </div>
  </ul>
</template>
<script lang="ts">
import SubSubMenu from "./SubSubMenu.vue";

export default {
  name: "SubMenu",
  props: ["blockMenu", "blockLinks", "blockImage", "active", "itemTitle"],
  components: { SubSubMenu },
  methods: {
    getIconName(itemClass: string | null): string {
      const classToIconMapping: { [key: string]: string } = {
        link_calendar: "calendar",
        link_account: "user",
        link_world: "world",
        link_donor: "donor"
      };
      //TODO: Changer l'icone par defaut
      return classToIconMapping[itemClass || ""] || "arrowRight";
    },
    getRelativePath(fullUrl: string): string {
      const url = new URL(fullUrl);
      return url.pathname;
    },
  },
};
</script>

<template>
  <div class="mt-8 border-t border-b border-grisClair border-b-grisFont">
    <div
        class="flex items-center p-4 text-regularMobile lg:text-lg font-normal font-raleway justify-end gap-[45px]"
    >
    <ClientOnly>
      <UiToggleLanguage ulClass="flex cursor-pointer z-50 relative" liClass="flex flex-col gap-2" iconClass="text-[1.3rem] cursor-pointer absolute top-[1px] left-5 ml-2 mb-0 transition-all transform icon-no-margin"/>
    </ClientOnly>
      <ClientOnly fallback-tag="div" fallback="Loading...">
        <UiToggleTheme/>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">

</script>
<style>
body {
  .icon-no-margin.nuxt-icon {
    svg {
      path {
        fill: transparent !important;
        stroke: #353537 !important;
      }
    }
  }
}
</style>
<template>
  <div
      class="h-[150px] w-full transition-all duration-300 ease-in"
      :class="navClasses"
      @mouseover="hovered = true"
      @mouseleave="isSubmenuActive ? hovered = false : hovered = true"
  >
    <div class="container flex mx-auto h-full">
      <div class="flex items-center w-1/12 cursor-pointer">
        <UiCustomLink path="/">
          <img
              v-if="isHomePage ? !isSubmenuActive && !hovered : false"
              src="~/assets/img/menu/logo-white.svg"
              alt="Curie Logo"
              class="transition-all duration-1000 ease-in"
          />
          <img
              v-else
              src="~/assets/img/menu/logo-color.svg"
              alt="Curie Logo"
              class="transition-all duration-1000 ease-in"
          />
        </UiCustomLink>
      </div>
      <div
          class="flex flex-col flex-wrap justify-evenly content-end w-11/12 font-raleway"
      >
        <Surnav :menuItems="menuSecondaryItems" :hovered="hovered"/>
        <div class="flex items-center relative justify-end">
          <MenuPrincipal
              :menuItems="menuItems"
              :activeItemIndex="activeItemIndex"
              @toggleSubmenu="updateSubmenuState"
          />
          <div>
            <div class="relative mr-6">
              <NuxtIcon name="search" filled class="text-regular cursor-pointer" :class="{ 'underline-search': activeItemIndex === 'search' }" @click="activate($event, 'search')"/>
            </div>
          </div>
          <UiSearch :activeItemIndex="activeItemIndex" :menuSearchItems="menuSearchItems"/>
          <div>
            <a target="_blank" :href="$t('donateLink')" @click="triggerEvent"
               class="group flex relative justify-center items-center bg-orangeCurie hover:bg-orangeHover text-white cursor-pointer text-lg leading-none font-bold font-raleway rounded py-4 px-6"
            >
              <NuxtImg
                  class="min-w-[23px]"
                  src="/image/heart-opacity-anim.svg"
                  alt="Image Donation"
              />
              <NuxtImg
                  class="absolute min-w-[23px] left-[2.1rem] top-4 group-hover:translate-x-[-8px] duration-500 block"
                  src="/image/heart-white-anim.svg"
                  alt="Image Donation"
              />
              <div class="ml-6">
                {{ $t('donation') }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../ui/Button.vue";
import Surnav from "./Surnav.vue";
import MenuPrincipal from "./MenuPrincipal";
import UiSearch from "~/components/ui/Search";
import {useMenuStore} from "~/stores/menu";
import { useRoute } from 'vue-router';

const store = useMenuStore();
const gtm = useGtm();

export default {
  name: "DesktopNav",
  components: {
    Button,
    Surnav,
    MenuPrincipal,
    UiSearch
  },
  setup() {
    const route = useRoute();
    watch(() => route.path, () => {
        store.unsetActiveIndex();
      });
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    menuSecondaryItems: {
      type: Array,
      required: true,
    },
    menuSearchItems: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    isHomePage() {
      if (this.$route.path === "/home" || this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
    activeItemIndex(){
      return store.activeIndex;
    },
    navClasses() {
      if (this.isHomePage && this.activeItemIndex===null) {
        return [
          "transition-all duration-300 ease-in",
          this.hovered
              ? "bg-white text-grisFont hovered"
              : "bg-transparent text-white",
        ];
      } else {
        return "bg-white text-grisFont hovered border-b border-grisClair";
      }
    },
  },
  methods: {
    activate(event, activeItemIndex){
      document.body.classList.add("modal-open");
      event.stopPropagation();
      this.updateSubmenuState(activeItemIndex)
    },
    updateSubmenuState(activeItemIndex) {
      if(activeItemIndex === store.activeIndex){
        store.unsetActiveIndex();
      }else{
        store.setActiveIndex(activeItemIndex);
      }
    },
    triggerEvent() {
      this.$gtm.trackEvent({
        event: 'cta_don',
        cta_text: useNuxtApp().$i18n.t("donation"),
        ux_position: 'header'
      })
    }
  },
};
</script>

<template>
    <div v-if="activeItemIndex == 'search'" @click="stopPropagation($event)" id="searchForm"
        class="w-full p-8 absolute transition ease-in-out top-[78px] px-8 left-[-35px]  bg-white border border-grisClair rounded-b-md text-lg z-10 max-w-[840px]">
        <div class="font-raleway text-medium font-bold pb-14"> {{ $t("nav.searchTitle") }}</div>
        <UiSearchForm :searchTerm="searchTerm" @submitForm="closeSearch"/>
        
        <div class="flex flex-row mt-[34px] mb-12" v-if="menuSearchItems">
            <ul class="flex gap-4 flex-wrap items-center">
            <li class="font-raleway font-bold text-regular pr-4"> {{ $t("nav.commonSearch") }} :</li>
                <li v-for="(item, index) in menuSearchItems" :key="index" class="font-raleway text-regular px-3 py-1 rounded bg-grisBackground">
                    <UiCustomLink :to="getSearchUrl(item.link.label)">
                        {{ item.link.label }}
                    </UiCustomLink>
                </li>
            </ul>
        </div>
        <div class="flex cursor-pointer justify-end underline text-regular font-raleway font-bold hover:text-orangeCurie" @click="closeSearch">
            {{ $t("close") }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { localize } from '@vee-validate/i18n';
import { useRouter } from 'vue-router';
import {useMenuStore} from "~/stores/menu";
import UiSearchForm from "~/components/ui/search/Form";
import getMenu from "../service/graphql/client/menu/index";
const store = useMenuStore();

const runtimeConfig = useRuntimeConfig();

/** ENd Form configuration */
const route = useRoute();
const router = useRouter();


const searchTerm = computed(
    () => ref(route.query.search)
);

const getSearchUrl = (searhTerm: string) => {
    return {
      name: 'search',
      query:{
        search: searhTerm
      }
    };
}

const props = defineProps({
  activeItemIndex: String,
  menuSearchItems: {
    type: Array,
    required: true,
  }
});

const stopPropagation = (e)=>{
 e.stopPropagation();
}

const closeSearch = () => {
  document.body.classList.remove("modal-open");
  store.unsetActiveIndex();
}
</script>
import { gql } from "graphql-tag";

export const MenuQuery = gql`
  query MenuQuery($menu: MenuName!) {
    menuByName(name: $menu) {
      links {
        link {
          url {
            path
          }
          label
          class: attribute(name: "class")
          target: attribute(name: "target")
          image: attribute(name: "image")
        }
        links: subtree {
          link {
            url {
              path
            }
            label
            class: attribute(name: "class")
            target: attribute(name: "target")
            image: attribute(name: "image")
          }
          links: subtree {
            link {
              url {
                path
              }
              label
              class: attribute(name: "class")
              target: attribute(name: "target")
              image: attribute(name: "image")
            }
            links: subtree {
              link {
                url {
                  path
                }
                label
                class: attribute(name: "class")
                target: attribute(name: "target")
                image: attribute(name: "image")
              }
            }
          }
        }
      }
    }
  }
`;

<template>
  <div>
    <ul class="p-4">
      <li v-for="(item, index) in blockImageLinks" :key="index">
        <span
          v-if="item.link.class !== 'link_image'"
          class="flex items-center my-2 text-lg font-bold underline font-raleway"
        >
          {{ item.link.label }}
          <nuxt-icon name="arrowRight" filled class="mt-1 ml-2" />
        </span>
        <NuxtImg
          v-if="item.link.class === 'link_image'"
          :src="`/image/${item.link.image}`"
          :alt="item.link.label"
          class="w-full"
        />
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { type MenuLinkTreeElement } from "~/interfaces/menu";

export default {
  name: "MobileBlocImage",
  props: {
    imageItems: {
      type: Array as () => MenuLinkTreeElement[],
      required: true,
    },
  },
  computed: {
    // Réorganiser le tableau pour que les images soient toujours en dernier
    blockImageLinks() {
      const links = [...this.imageItems];
      if (links.length > 0 && links[0].link.class === "link_image") {
        const imageLink = links.shift();
        if (imageLink) {
          links.push(imageLink);
        }
      }
      return links;
    },
  },
};
</script>

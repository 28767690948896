<template>
  <div class="relative z-20 w-full overflow-y-auto bg-white">
    <div class="flex items-center h-[72px] border-b border-grisClair">
      <div class="flex items-center h-full px-4 mr-auto cursor-pointer">
        <div
          class="flex flex-col items-center justify-center h-full pr-4 border-r border-gray-300 w-14"
          @click="toggleMenu"
        >
          <nuxt-icon :name="menuIcon" filled class="m-0 text-4xl" />
          <span class="text-xs font-normal font-raleway">{{ menuText }}</span>
        </div>
        <UiCustomLink path="/" class="w-1/3 ml-6">
          <img src="~/assets/img/menu/logo-color.svg" alt="Curie Logo" class="" />
        </UiCustomLink>
      </div>
      <div class="flex items-center h-full px-4 bg-cover bg-orangeCurie">
        <a :href="$t('donateLink')" target="_blank" class="flex flex-col items-center justify-center">
          <nuxt-icon name="heart" filled class="text-2xl m-O" />
          <span class="text-lg font-extrabold leading-none text-white font-raleway">
            {{ $t("donation") }}
          </span>
        </a>
      </div>
    </div>
    <MobileMenuPrincipal v-if="showNav" :menuItems="menuItems" :menuSecondaryItems="menuSecondaryItems" @closeMenu="toggleMenu" />
  </div>
</template>

<script setup lang="ts">
import { MenuMobileMenuPrincipal as MobileMenuPrincipal } from "#components";
import {useHead} from '@unhead/vue';
const { activeIndex } = storeToRefs(useMenuStore());

defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
  menuSecondaryItems: {
    type: Array,
    required: true,
  },
});

const menuIcon = ref("menu");
const menuText = ref("Menu");
const showNav = ref(false);
const route = useRoute();

const toggleMenu = () => {
  if (!showNav.value) {
    menuIcon.value = "close";
    menuText.value = "Fermer";
    showNav.value = true;
    document.querySelector('header')?.classList.add('h-dvh');
    document.body.classList.add("modal-open");
  } else {
    menuIcon.value = "menu";
    menuText.value = "Menu";
    showNav.value = false;
    document.querySelector('header')?.classList.remove('h-dvh');
    document.body.classList.remove("modal-open");
  }
};

watch(
  () => activeIndex?.value,
  (value) => {
    if (value === "search") {
      toggleMenu();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
);


watch(() => route.path, () => {
      showNav.value = false;
      document.querySelector('header')?.classList.remove('h-dvh');
      document.body.classList.remove("modal-open");
      menuIcon.value = "menu";
      menuText.value = "Menu";
  });

</script>

<template>
  <div class="w-full py-4 bg-orangeCurie border-b border-white lg:border-0">
    <div class="container flex items-center mx-auto text-sm text-white">
      <img
          :src="
          popupItems.entityQuery.items[0].fieldImage.entity.uriRawField.list[0]
            .url
        "
          :alt="popupItems.entityQuery.items[0].alt"
          class="w-[41px] mr-4"
      />
      <div class="font-raleway  flex gap-2 flex-col lg:inline">
        <span class="font-bold text-small">{{
            popupItems.entityQuery.items[0].fieldTitle
          }}</span>
        <span class="hidden lg:inline">{{ popupItems.entityQuery.items[0].fieldTextBanner }}</span>
        <a
            :href="popupItems.entityQuery.items[0].fieldLink.uri.path"
            class="font-bold underline text-regularMobile lg:text-small lg:ml-2"
            @click="triggerEventClic"
        >
          {{ popupItems.entityQuery.items[0].fieldLink.title }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const gtm = useGtm();

import type { PopupItemsProps } from "~/interfaces/popupItems";

const props = defineProps({
  popupItems: {
    type: Array as PropType<PopupItemsProps[]>,
    required: true,
  },
});

const triggerEventShow = () => {
  gtm.trackEvent({
    event: 'banner_view',
    popin_title: props.popupItems.entityQuery.items[0].fieldTitle
  })
}

const triggerEventClic = () => {
  gtm.trackEvent({
    event: 'banner_click',
    banner_title: props.popupItems.entityQuery.items[0].fieldTitle,
    banner_url: props.popupItems.entityQuery.items[0].fieldLink.uri.path
  })
}

triggerEventShow();
</script>

<template>
  <nav>
    <ul class="relative lg:gap-6 xl:gap-10 flex mr-6 text-regular font-bold">
      <MenuElement
        v-for="(item, index) in menuItems"
        :key="index"
        :item="item"
        :index="index"
        :activeItemIndex="activeItemIndex"
        @toggleSubmenu="toggleSubmenu"
        @click="stopPropagation"
      />
    </ul>
  </nav>
</template>

<script>
import MenuElement from "./MenuElement.vue";

export default {
  name: "MenuPrincipal",
  components: {
    MenuElement,
  },
  props: ["menuItems", "activeItemIndex"],
  methods: {
    toggleSubmenu(index) {
      this.$emit("toggleSubmenu", index);
    },
    closeSubmenuOnOutsideClick(event) {
      this.$emit("toggleSubmenu", null);
    },
    stopPropagation(event) {
      event.stopPropagation();
    }
  },
  mounted() {
    // Ajoutez un gestionnaire d'événements de clic au niveau de la page
    document.addEventListener("click", this.closeSubmenuOnOutsideClick);
  },
  beforeDestroy() {
    // Retirez le gestionnaire d'événements lors de la destruction du composant
    document.removeEventListener("click", this.closeSubmenuOnOutsideClick);
  },
};
</script>

<template>
  <nav>
    <MobileMenuElement
        v-if="!selectedMenuItem"
        :menuItems="menuItems"
        @show-sub-menu="handleShowSubMenu"
    />
    <div v-else>
      <span
          @click="handleBackClick"
          class="flex items-center px-4 pb-4 my-4 text-regularMobile lg:text-lg font-normal border-b border-grisClair cursor-pointer font-raleway"
      >
        <nuxt-icon name="chevronLeft" filled class="mr-8 text-4xl"/>
        {{ $t("nav.mainMenu") }}
      </span>
      <MobileSubMenu
          v-if="selectedMenuItem && selectedMenuItem.links.length > 0"
          :selectedMenuItem="
          findMenuItemByClass(selectedMenuItem?.links, 'block_menu')
        "
      />
      <MobileBlocLinks
          v-if="selectedMenuItem && selectedMenuItem.links.length > 0"
          :linksItems="
          findMenuItemByClass(selectedMenuItem.links, 'block_links')?.links ||
          []
        "
      />
      <MobileBlocImage
          v-if="selectedMenuItem && selectedMenuItem.links.length > 0"
          :imageItems="
          findMenuItemByClass(selectedMenuItem.links, 'block_image')?.links ||
          []
        "
      />
    </div>
    <div class="mb-8 px-4">
      
        <UiSearchForm :searchTerm="searchTerm" @submitForm="closeMenu" :formClass="formClass" :inputClass="inputClass" :buttonClass="buttonClass" :iconClass="iconClass"/>
      
      <Search v-if="!selectedMenuItem"/>
    </div>
    <MobileSubNav :subMenuItem="menuSecondaryItems"/>
    <MobileNavFooter />
  </nav>
</template>
<script setup lang="ts">

import Search from "~/components/ui/Search";
import MobileMenuElement from "./MobileMenuElement";
import MobileSubNav from "./MobileSubNav";
import MobileNavFooter from "./MobileNavFooter";
import MobileSubMenu from "./MobileSubMenu";
import MobileBlocLinks from "./MobileBlocLinks";
import MobileBlocImage from "./MobileBlocImage";
import UiSearchForm from "~/components/ui/search/Form";

  const props = defineProps({
    menuItems: {
        type: Array,
        required: true,
      },
    menuSecondaryItems: {
      type: Array,
      required: true,
    },
  });

  const formClass="justify-between flex flex-wrap flex flex-row w-full items-center border-t  border-grisClair border-b pt-4 pb-4";
  const inputClass="w-full text-regular font-raleway placeholder:font-raleway placeholder:text-regular  px-4 py-3  self-stretch border border-grisClair focus:outline-0 border-r-0";
  const buttonClass="absolute right-[15px] w0 p-[15px] lg:p-[20px] bg-orangeCurie flex justify-center items-center self-stretch rounded-tr-[3px] rounded-br-[3px]"
  const iconClass="text-white text-[1.3rem] cursor-pointer";

  const selectedMenuItem = ref(null);

  const emit = defineEmits(['closeMenu'])
  const handleShowSubMenu = (item) => {
    selectedMenuItem.value = item;
  };
  const  handleBackClick = () => {
    selectedMenuItem.value = false;
  };
  const findMenuItemByClass = (links, className) => {
    if (!links) return null;
    return links.find((link) => link.link.class === className);
  };
  const closeMenu= () => {
    emit("closeMenu");
  };



</script>

<template>
  <nav>
    <ul class="flex justify-end text-sm items-center font-normal lg:gap-6 xl:gap-10 lg:pl-4">
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        class="hover:opacity-50"
      >
        <UiCustomLink :path="item.link.url.path">{{
          item.link.label
        }}</UiCustomLink>
      </li>
      <li class="flex gap-2 align-center">
          <ClientOnly fallback-tag="div" fallback="Loading...">
            <ToggleTheme />
          </ClientOnly>
      </li>
      <li>
        <ClientOnly>
          <ToggleLanguage />
        </ClientOnly>
      </li>
    </ul>
  </nav>
</template>
<script>
import ToggleLanguage from "~/components/ui/ToggleLanguage.vue";
import ToggleTheme from "~/components/ui/ToggleTheme.vue";
import search from "~/components/ui/Search.vue";

export default {
  name: "Surnav",
  components: {
    ToggleLanguage,
    ToggleTheme,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    hovered: false,
  },
};
</script>

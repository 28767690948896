<template>
  <div v-if="showPopup" class="modal-overlay" @click="handleOutsideClick">
    <div class="modal" @click.stop>
      <div
        class="flex flex-col justify-center items-center mx-auto w-3/5 h-full font-normal text-center text-white font-raleway"
      >
        <UiCustomImage
          :src="popupItems[0].fieldImage.entity.uriRawField.list[0].url"
          :alt="popupItems[0].fieldImage.alt"
          presets="alert_image"
        />
        <h2 class="text-[44px] leading-[52px] mb-10">
          {{ popupItems[0].fieldTitle }}
        </h2>
        <div
          v-html="popupItems[0].fieldTextPopup"
          class="text-lg leading-[25px]"
        />
        <a
          :href="popupItems[0].fieldLink.uri.path"
          class="text-lg font-bold leading-[25px] underline mt-4 flex items-center"
          @click="triggerEventClic"
        >
          {{ popupItems[0].fieldLink.title }}
          <nuxt-icon name="arrowRight" filled class="ml-2" />
        </a>
        <button
          @click="closePopup"
          class="px-6 py-2 mt-8 text-lg font-bold bg-white rounded-md text-orangeCurie"
        >
          {{  $t("gotIt") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
const gtm = useGtm();

const props = defineProps({
  popupItems: {
    type: Array,
    required: true,
  },
});
const showPopup = ref(false);

const checkPopup = () => {
  const alertPopup = localStorage.getItem("alertPopup");

  const now = new Date();
  if (alertPopup) {
    const lastPopupTime = new Date(alertPopup);
    const diff = now.getTime() - lastPopupTime.getTime();
    if (diff > 86400000) {
      showPopup.value = true;
      document.body.classList.add("modal-open");
    }
  }else {
    showPopup.value = true;
    document.body.classList.add("modal-open");
  }
  if(showPopup.value === true) {
    triggerEventShow();
  }

};

const triggerEventShow = () => {
  gtm.trackEvent({
    event: 'popin_view',
    popin_title: props.popupItems[0].fieldTitle
  })
}

const triggerEventClic = () => {
  gtm.trackEvent({
    event: 'popin_click',
    popin_title: props.popupItems[0].fieldTitle,
    popin_url: props.popupItems[0].fieldLink.uri.path
  })
}

const closePopup = () => {
  localStorage.setItem("alertPopup", new Date().toISOString());
  showPopup.value = false;
  document.body.classList.remove("modal-open");
};

const handleOutsideClick = (event) => {
  if (event.target === event.currentTarget) {
    closePopup();
  }
};

checkPopup();
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000da;
  z-index: 1000;
}

.modal {
  background-color: #f58220;
  width: 80%;
  border-radius: 4px;
  padding: 50px 0;
}
</style>

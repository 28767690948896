import { gql } from "graphql-tag";

export const BreadcrumbQuery = gql`
  query BreadcrumbQuery($path: String!) {
    route(path: $path) {
      ... on EntityCanonicalUrl {
        breadcrumb {
          title
          url {
            path
          }
        }
      }
    }
  }
`;

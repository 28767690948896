import { MenuQuery } from "../../query/menu/NavigationMainFrench";
import type { MenuItems } from "~/interfaces/menu";

export default async function getMenu(menuCode: string) {
  const TAGS = [];
  const { data: menuItems } = await useAsyncData(menuCode, async () => {
    const { value, addToCache } = await useDataCache(menuCode)
    if (value) {
      return value
    }
    const { data: response } = await useAsyncQuery<MenuItems>(MenuQuery, { menu: menuCode });
    TAGS.push(`menu:${menuCode}`);
    await addToCache(response._value, TAGS);
    return response._value;
  })
  return {data: menuItems._value };
}

import { BreadcrumbQuery } from "../../query/breadcrumb/Breadcrumb";
import type { BreadcrumbItems } from "~/interfaces/breadcrumb";

export default async function getBreadcrumb(path: String) {
  const KEY = `BREADCRUMB-${path.split("/")[1]}`;
  const TAGS = [];
  const { data: breadcrumbItems } = await useAsyncData(KEY, async () => {
    const { value, addToCache } = await useDataCache(KEY)
    if (value) {
      return value
    }
    const { data: response } = await useAsyncQuery<BreadcrumbItems>(BreadcrumbQuery, { path });
    TAGS.push(`breadcrumb:${path.split("/")[1]}`);
    await addToCache(response._value, TAGS);
    return {value: response._value};
  })

  return {data: breadcrumbItems.value ?  breadcrumbItems.value : breadcrumbItems};
}

<template>
  <div class="px-4 my-6">
    <ul>
      <li class="mb-6">
        <UiCustomLink
          :path="selectedMenuItem.link.url.path"
          class="text-lg font-bold font-raleway"
        >
          {{ selectedMenuItem.link.label }}
        </UiCustomLink>
      </li>
      <li
        v-for="(subItem, index) in selectedMenuItem.links"
        :key="index"
        class="pb-3 my-4 border-grisClair last:border-0"
        :class="selectedSubMenuItem === subItem ? 'border-b-0' : 'border-b'"
      >
        <NuxtLink
          v-if="subItem.links.length > 0"
          @click.prevent="
            subItem.links.length > 0
              ? handleShowSubSubMenu(subItem)
              : $router.push(subItem.link.url.path)
          "
          class="flex items-center justify-between text-lg font-normal cursor-pointer font-raleway"
        >
          {{ subItem.link.label }}
          <nuxt-icon
            v-if="subItem.links.length > 0"
            :name="selectedSubMenuItem === subItem ? 'minus' : 'plus'"
            filled
            class="text-2xl"
          />
        </NuxtLink>
        <UiCustomLink
          v-if="subItem.links.length == 0"
          :path="subItem.link.url.path"
          class="flex items-center justify-between text-lg font-normal cursor-pointer font-raleway"
        >
          {{ subItem.link.label }}
        </UiCustomLink>
        <ul
          v-if="selectedSubMenuItem === subItem"
          class="bg-gray-100 mx-[-16px] px-2"
        >
          <li
            v-for="(subSubItem, subIndex) in subItem.links"
            :key="subIndex"
            class="px-4 py-1 my-4"
          >
            <UiCustomLink
              :path="subSubItem.link.url.path"
              class="text-lg font-normal font-raleway"
            >
              {{ subSubItem.link.label }}
            </UiCustomLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MobileSubMenu",
  props: {
    selectedMenuItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSubMenuItem: null,
    };
  },
  methods: {
    handleShowSubSubMenu(item) {
      this.selectedSubMenuItem =
        this.selectedSubMenuItem === item ? null : item;
    },
  },
};
</script>

<template>
  <footer class="pt-14 pb-[45px] lg:py-16 bg-orangeCurie">
    <div class="container mx-auto">
      <div
          class="justify-between pb-8 border-b lg:pb-14 border-orangeLight lg:border-b lg:border-orangeLight lg:flex"
      >
        <img
            src="~/assets/img/menu/logo-white.svg"
            alt="Logo Curie"
            class="w-[130px] lg:w-[180px] pb-8 lg:pb-0"
        />
        <div class="flex-col font-normal text-white lg:flex font-raleway">
          <div class="lg:justify-end flex  text-h2Small lg:text-h2 mb-4 font-normal">
            {{ $t("footer.title") }}
          </div>
          <p class="text-regular mb-8">
            {{ $t("footer.description") }}
          </p>
          <UiButton
              :buttonLabel="$t('footer.newsletterLabel')"
              :to="urlNewsletter"
              size="large"
              :withIcon="false"
              variant="secondaryAlt"
              class="block mb-6 w-full text-center border-0 lg:mb-0 lg:mt-0 text-orangeCurie hover:border-0 lg:w-auto lg:hidden"
              target="_blank"
          />
          <div
              class="flex flex-col-reverse justify-start items-start lg:items-center lg:border-0 lg:justify-end md:flex-col lg:flex-row"
          >
            <div class="flex gap-4 mr-4">
              <UiCustomLink
                  v-for="(icon, index) in icons"
                  :key="index"
                  :path="icon.url"
                  class="p-3.5 rounded-lg border-white border-1-1/2 flex items-center group hover:bg-white"
              >
                <nuxt-icon
                    :name="icon.icon"
                    class="group-hover:text-orangeCurie"
                />
              </UiCustomLink>
            </div>
            <UiButton
                external="external"
                :to="urlNewsletter"
                :buttonLabel="$t('footer.newsletterLabel')"
                size="large"
                :withIcon="false"
                variant="secondaryAlt"
                class="hidden mb-6 w-full text-center border-0 lg:mb-0 lg:mt-0 hover:bg-greyMedium hover:border-0 text-orangeCurie lg:w-auto lg:block"
                target="_blank"
            />
          </div>
        </div>
      </div>
      <div
          class="flex-col items-end pt-8 text-white lg:pt-10 lg:flex font-raleway"
      >
        <ul
            class="text-regular lg:text-lg font-bold md:flex md:gap-x-[18px] md:justify-start md:flex-wrap"
        >
          <li
              v-for="(item, index) in menuItems"
              :key="index"
              class="mb-4 lg:my-0"
          >
            <UiCustomLink :path="item.link.url.path">
              {{ item.link.label }}
            </UiCustomLink>
          </li>
        </ul>
        <ul
            class="mt-10 lg:mt-6 md:flex md:gap-x-6 md:justify-start md:flex-wrap"
        >
          <li
              v-for="(item, index) in menuSecondaryItems"
              :key="index"
              class="mb-4 text-sm font-normal lg:my-0"
          >
            <UiCustomLink :path="item.link.url.path">
              {{ item.link.label }}
            </UiCustomLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const urlNewsletter = runtimeConfig.public.urlNewsletter;

const props = defineProps({
   menuItems: {
      type: Object,
      required: true,
    },
    menuSecondaryItems: {
      type: Object,
      required: true,
    },
});

const icons= [
  {
    icon: "Facebook",
    url: "https://www.facebook.com/InstitutCurie",
  },
  {
    icon: "Twitter",
    url: "https://twitter.com/institut_curie",
  },
  {
    icon: "LinkedIn",
    url: "https://www.linkedin.com/company/institutcurie/",
  },
  {
    icon: "Instagram",
    url: "https://www.instagram.com/institut_curie/",
  }
];
const menu2= {
  links: [
    {
      label: "Mentions légales",
      url: "/qui-sommes-nous",
    },
    {
      label: "Politique des données personnelles",
      url: "/notre-equipe",
    },
    {
      label: "Gestion des cookies",
      url: "/nos-partenaires",
    },
  ],
};
</script>

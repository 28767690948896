<template>
  <ul class="px-4">
    <li v-for="(item, index) in subMenuItem" :key="index" class="my-4">
      <UiCustomLink
        :path="item.link.url.path"
        class="flex items-center text-regularMobile lg:text-lg font-normal font-raleway"
      >
        {{ item.link.label }}
      </UiCustomLink>
    </li>
  </ul>
</template>
<script>
export default {
  name: "MobileSubNav",
  props: {
    subMenuItem: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="flex flex-row-reverse lg:flex-row items-center gap-2">
    <label for="constrast" class="hover:opacity-50 cursor-pointer">
      {{ $t("contrast") }}
      <span v-if="isDark">( {{ $t("inactive") }} )</span>
      <span v-if="!isDark">( {{ $t("active") }} )</span>
    </label>
    <label class="inline-flex items-center cursor-pointer">
      <input type="checkbox" name="constrast" id="constrast" value="theme-dark" @change="isDark = !isDark" v-model="isDarkTheme"
             class="sr-only peer focus:outline-offset-0 focus:outline-0"/>
      <div
          class="ConstrastSwitcher border border-grisFont relative w-[50px] h-[26px] peer-checked:after:translate-x-[120%] after:h-5 after:w-5 lg:w-[29px] lg:h-[14px] bg-transparent peer-focus:outline-none  rounded-full peer lg:peer-checked:after:translate-x-[170%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-grisFont after:content-['']  after:absolute after:top-[2px] after:start-[2px] after:bg-grisFont after:border-grisClair after:border after:rounded-full lg:after:h-2 lg:after:w-2 after:transition-all peer-checked:bg-white"
      ></div>
      <span
          class="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300"
      ></span>
    </label>
  </div>
</template>
<script lang="ts">
import {useHead} from '@unhead/vue';
import {useThemeStore, DEFAULT_THEME, DARK_THEME} from "~/stores/theme";
import {ref} from "vue";

const store = useThemeStore();
const isDark = ref(false);
export default {
  name: "ToggleTheme",
  components: {},

  data() {
    store.init();
    const value = store.theme == DEFAULT_THEME ? ref([]) : ref([DARK_THEME]);
    return {
      isDarkTheme: value,
      isDark: false,
    };
  },
  watch: {
    isDarkTheme(newValue) {
      if (newValue.flat().length > 0) {
        store.setTheme(DARK_THEME);
      } else {
        store.setTheme(DEFAULT_THEME);
      }
    },
  },
  setup() {
    useHead({
      bodyAttrs: {
        class: computed(() => (store.theme))
      }
    })
  }
};


</script>
<style>
.hovered {
  .ConstrastSwitcher {
    @apply border-grisFont;

    &:after {
      @apply bg-grisFont
    }
  }
}

.is-homepage {
  .ConstrastSwitcher {
    @apply lg:border-white;

    &:after {
      @apply lg:bg-white;
    }
  }

  .hovered {
    .ConstrastSwitcher {
      @apply lg:border-grisFont;

      &:after {
        @apply lg:bg-grisFont
      }
    }
  }
}
</style>

<template>
  <ul
      :class="ulClass" @click="showList = !showList"
  >
    <li :class="liClass">
      <UiCustomLink
          v-for="(locale , index) in locales"
          :path="`${getUrl(locale).value}`"
          :key="index"
          class=""
          :class="showList ? '' : 'last:hidden'"
      >
        {{ locale }}
      </UiCustomLink>
    </li>
    <NuxtIcon
        :class="(showList ? 'rotate-180' : '') + ' ' +iconClass"
        name="chevronDownHeader"/>
  </ul>
</template>

<script setup lang="ts">
import {ref, computed} from "vue";
const runtimeConfig = useRuntimeConfig();

import {useContextStore} from "~/stores/context";
const contextStore = useContextStore();

const showList = ref(false);

interface Props {
  liClass: string,
  iconClass: string,
  ulClass: string
}

withDefaults(defineProps<Props>(), {
  liClass: "flex absolute flex-col",
  iconClass: "text-[1.3rem] cursor-pointer text-grisFont ml-6 mb-0 transition-all transform icon-no-margin",
  ulClass: "flex cursor-pointer z-99"
});



const locales =["FR", "EN"];
const currentLocale :string = runtimeConfig.public.langcode;
const currentLocaleIndex: number = locales.findIndex((locale) => locale == currentLocale);
if(currentLocaleIndex>0){
  locales.unshift(locales[currentLocaleIndex]);
  locales.splice(currentLocaleIndex+1, 1);
}

const getDomainUrl = (locale: string) => {
  if(locale !== currentLocale){
    return runtimeConfig.public[locale+"_Host"];
  }else{
    return runtimeConfig.public.frontendHost;
  }
}

const getUrl = (locale :string) => {
  return computed(() => {
    for(let i=0; i< contextStore.content?.translations.length; i++){
      if(contextStore.content?.translations[i].pathRawField?.list[0]?.langcode == locale.toLowerCase()){
        return `${getDomainUrl(locale)}${contextStore.content?.translations[i].pathRawField.list[0].alias}`;
      }
    }
    return getDomainUrl(locale);
  });
}

</script>
<style>
body {
  .icon-no-margin.nuxt-icon {
    svg {
      margin-bottom: 0 !important;

      path {
        fill:transparent !important;
        stroke:#353537!important;
      }
    }
  }
}

@media(min-width: 1024px){
  .is-homepage {
    .icon-no-margin.nuxt-icon {
      svg path {
        fill: transparent !important;
        stroke: white !important;
      }
    }

    .hovered {
      .icon-no-margin.nuxt-icon {
        svg path {
          stroke:#353537!important;
        }
      }
    }
  }
}
</style>

import { defineStore } from "pinia";

export const DEFAULT_THEME = "theme-light";
export const DARK_THEME = "theme-dark";
export const THEME_STORE_NAME = "theme";

export const useThemeStore = defineStore(THEME_STORE_NAME, {
    state: () => ({
        _theme: DEFAULT_THEME,
    }),
    actions: {
        setTheme(value: string) {
            this._theme = value;
            localStorage.setItem(THEME_STORE_NAME, value);
        },
        init(){
            if(localStorage.getItem(THEME_STORE_NAME)){
                this._theme = localStorage.getItem(THEME_STORE_NAME);
            }
        },
    },
    getters: {
        theme: (state) =>  state._theme,
    },
});
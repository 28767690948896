<template>
  <div v-if="showPopup" class="modal-overlay" @click="handleOutsideClick">
    <div
      class="relative flex flex-col max-h-[85vh] lg:max-h-[100vh] overflow-y-scroll lg:overflow-y-visible lg:flex-row modal w-[100%] mx-4 lg:w-[1080px]"
    >
      <nuxt-icon
        name="close-modal"
        filled
        class="absolute text-2xl text-white lg:text-grisFont cursor-pointer right-[13px] top-[13px] lg:right-[24px] lg:top-[24px]"
        @click="closePopup"
      />
      <div class="lg:w-[680px]">
        <UiCustomImage
          :src="popupItems[0].fieldImage.entity.uriRawField.list[0].url"
          :alt="popupItems[0].fieldImage.alt"
          class="object-cover lg:max-h-[100%] lg:h-full w-full rounded-l-[4px]"
          presets="image_modal"
        />
      </div>
      <div
        class="flex flex-col justify-center p-4 pt-6 w-full font-normal lg:w-[400px]  align-center lg:p-12 lg:pr-14 lg:pt-[46px] lg:pb-[44px] font-raleway text-grisFont"
      >
        <h5
          v-if="popupItems[0].fieldSubtitle"
          class="text-lg font-medium font-lora text-orangeCurie"
        >
          {{ popupItems[0].fieldSubtitle }}
        </h5>
        <h3 class="text-[2rem] lg:text-h3 leading-[35px] lg:leading-[45px] mb-2 lg:mb-4">
          {{ popupItems[0].fieldTitle }}
        </h3>
        <div
          v-html="popupItems[0].fieldBody"
          class="text-regular wysiwyg leading-[25px]"
        />
        <a
          class="px-6 py-4 mt-6 lg:mt-4 w-full text-lg font-bold text-center text-white rounded-md hover:bg-orangeHover lg:w-fit bg-orangeCurie"
          :href="popupItems[0].fieldLink.uri.path"
          @click="triggerEventClic"
        >
          {{ popupItems[0].fieldLink.title }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
const gtm = useGtm();

const props = defineProps({
  popupItems: {
    type: Array,
    required: true,
  },
});

const showPopup = ref(false);

const checkPopup = () => {
  const marketingPopup = JSON.parse(localStorage.getItem("marketingPopup"));
  const now = new Date();
  if (marketingPopup && marketingPopup.id == props.popupItems[0].id) {
    const lastPopupTime = new Date(marketingPopup.date);
    const diff = now.getTime() - lastPopupTime.getTime();
    if (diff > 86400000 && marketingPopup.counter <= 3) {
      showPopup.value = true;
    }
  } else {
    showPopup.value = true;
  }
  if(showPopup.value === true) {
    document.body.classList.add("modal-open");
    triggerEventShow();
  }
};

const closePopup = () => {
  const marketingPopup = JSON.parse(localStorage.getItem("marketingPopup"));
  let initCounter = 0;
  if(!marketingPopup) {                                     //First Time
    initCounter = 1;
  }
  else if (marketingPopup.id != props.popupItems[0].id) {   //Another popin
    initCounter = 1;
  }
  else {
    initCounter = marketingPopup.counter + 1;               //Same Popin already displayed => we increment
  }

  const popupData = {
    id: props.popupItems[0].id, 
    date: new Date().toISOString(),
    counter: initCounter
  };
  localStorage.setItem("marketingPopup", JSON.stringify(popupData));
  showPopup.value = false;
  document.body.classList.remove("modal-open");
};

const handleOutsideClick = (event) => {
  if (event.target === event.currentTarget) {
    closePopup();
  }
};

const triggerEventShow = () => {
  gtm.trackEvent({
    event: 'popin_view',
    popin_title: props.popupItems[0].fieldTitle
  })
}

const triggerEventClic = () => {
  gtm.trackEvent({
    event: 'popin_click',
    popin_title: props.popupItems[0].fieldTitle,
    popin_url: props.popupItems[0].fieldLink.uri.path
  })
}

checkPopup();
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 4px;
}
</style>

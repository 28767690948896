<template>
  <ul class="px-4">
    <li
      v-for="(item, index) in menuItems"
      :key="index"
      class="pb-3 my-4 border-b border-grisClair last:border-0"
    >
      <NuxtLink
        class="flex items-center justify-between text-regularMobile lg:text-lg font-bold cursor-pointer font-raleway"
        @click="handleClick(item)"
      >
        {{ item.link.label }}
        <nuxt-icon name="chevronRight" filled class="text-2xl m-O" />
      </NuxtLink>
    </li>
  </ul>
</template>
<script>
export default {
  name: "MobileMenuElement",
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(item) {
      this.$emit("show-sub-menu", item);
    },
  },
};
</script>

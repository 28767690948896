<template>
  <Form ref="form" :class="formClass" :validation-schema="schema" @submit="onSubmit" v-slot="{ errors }" >
      <Field
          :class="inputClass"
          type="search" name="search" :value="searchTerm" placeholder="Recherchez un nom de médecin, une équipe de recherche..."/>
      
      <button :class="buttonClass" type="submit" :value="$t('Send')"><NuxtIcon name="search" filled :class="iconClass"/></button>

      <span v-if="errors.search" class="w-full pt-2 font-raleway text-small text-red">{{ errors.search }}</span>
  </Form>
</template>
<script setup lang="ts">
import { Form, Field, defineRule, configure } from 'vee-validate';
import { required, min, max } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import { useRouter } from 'vue-router';


/** Form Configuration */
defineRule('required', required);
defineRule('min', min);

configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'Please fill 3 characters or more',
      min: 'Please fill 3 characters or more',
    },
  }),
});

configure({
  // Generates an Frensh message locale generator
  generateMessage: localize('fr', {
    messages: {
      min: 'Merci de taper une recherche 3 caractères minimum',
      required: 'Merci de taper une recherche 3 caractères minimum',
    },
  }),
});

const schema = {
  search: {
    required: true,
    min:3
  }
};

const emit = defineEmits(['submitForm'])

/** ENd Form configuration */
const route = useRoute();
const router = useRouter();

const props= defineProps({
  formClass: {
    type: String,
    default: "justify-between flex flex-wrap flex border-b border-grisClair pb-3.5"
  },
  inputClass:{
    type: String,
    default: "w-11/12 focus:outline-0 focus:border-0 placeholder:font-italic placeholder:font-ralewat placeholder:text-regular"
  },
  buttonClass:{
    type: String,
    default: ""
  },
  iconClass:{
    type: String,
    default: "text-2xl  cursor-pointer"
  },
});

const searchTerm = computed(
    () => ref(route.query.search)
);

const onSubmit = async (values) => {
  const searchRoute = router.resolve({ name: 'search' });
  await navigateTo(
    {
      path: searchRoute.path,
      query:{
        search: values.search
      }
    }
  );
  emit('submitForm', true);
}
</script>